.allabrf--walls {
  .profile {
    max-width: 980px;
  }

  .modal {
    @media screen and (min-width: $lg) {
      .popup-module {
        min-width: 480px;
      }
    }

    .form-group submit {
      text-align: center;
    }
  }

  textarea {
    display: block;
    padding: 10px;
    min-height: 150px;
    width: 100%;
    border: 1px solid $grayLightest;
    font-weight: 300;
    font-size: 14px;
    font-family: inherit;
    resize: vertical;

    &:focus {
      border: 1px solid $grayLight;
    }
  }

  .wall_component--body {
    margin-bottom: 16px;
    border: 1px solid $grayLighter;
    border-radius: 4px;

    p a {
      color: $purple;
    }
  }

  .get-centered {
    display: inline-block;
  }

  .create-post--content {
    padding: 16px;
    border-color: rgba(0, 0, 0, 0.25);
    background: lighten($lightBlue, 40%);
    text-align: center;

    h3 {
      font-weight: 400;
    }
  }

  /*
    Everything related to the post object below
  */
  .wall-post--avatar  {
    height: 32px;
  }

  .wall-post--author {
    min-width: 150px;
    max-width: 90%;
  }

  .wall-post--content {
    margin: 16px;
  }

  .wall-post-content {
    margin-bottom: 16px;
    white-space: pre-wrap;
  }

  .wall-post-attachments {
    overflow-x: hidden;
    width: 100%;

    > p {
      margin-bottom: 16px;
      max-width: 200px;
      border-bottom: 1px solid $grayLight;
    }
  }

  .attached-image:nth-child(3n) {
    img {
      margin-right: 0;
    }
  }

  .attached-document:nth-child(3n) {
    margin-right: 0;
  }

  .attached-document {
    margin: 0 16px 16px 0;
    padding-top: 16px;
    min-height: 179px;
    width: 179px;
    border: 1px solid $grayLighter;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;

    p {
      padding-top: 5px;
      color: $purple !important;
      text-decoration: underline;
      font-weight: 400;
      font-size: 12px !important;

      text-decoration-color: $grayLight;
    }

    img {
      display: block;
      margin: 0 auto;
      margin-top: 16px;
    }

    &:hover {
      border: 1px solid $grayLight;

      p {
        text-decoration-color: $purple;
      }
    }
  }

  .attached-image {
    img {
      margin: 0 16px 16px 0;
      width: 179px;
    }
  }

  .wall-post-attachment {
    display: block;
    white-space: nowrap;
    font-size: 14px;

    &:hover  {
      text-decoration: underline;
    }
  }

  .wall-post-edit-icon--square {
    margin: 16px;
    padding-top: 10px;
    padding-left: 12px;
    width: 9px;
    background-image: $icon_edit_square;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover  {
      background-image: url($icon_edit_square_purple);
    }
  }

  .wall-post-comment--body {
    position: relative;
    margin-bottom: 16px;
    color: $grayDarker;
    font-size: 14px;

    .wall-post-edit-icon--square {
      margin-top: 4px;
      margin-right: 8px;
    }

    h6 {
      color: $gray;
      font-weight: bold;
    }

    h7 {
      float: right;
    }
  }

  .wall-post--header {
    position: relative;
    margin-bottom: 16px;

    > div {
      img,
      div {
        float: left;
      }
    }

    h2 {
      color: $grayDarker;
      font-size: 16px;
      line-height: 30px;
    }

    span {
      font-weight: 300;
      font-size: 16px;
    }

    .wall-post--author,
    .wall-post--tooltip,
    .wall-post--sticky-pin {
      line-height: 32px;
    }
  }

  .wall-post--body {
    margin-bottom: 16px;

    p {
      color: $gray;
      word-wrap: break-word;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .wall-post--footer > h3 {
    color: $purple;
    font-size: 14px;
  }

  .wall-post--sticky-pin {
    position: relative;
    margin-top: 8px;
    padding: 0 6px;
    height: 20px;
    background-repeat: no-repeat;
  }

  .wall-post--sticky-pin-pinned {
    background-image: url($icon_pinned_pin);
  }

  .wall-post--sticky-pin-not-pinned {
    background-image: url($icon_pin);
  }

  .wall_post--sticky-pin-can-edit {
    cursor: pointer;

    &:hover  {
      background-image: url($icon_pinned_pin);

      .wall-post-pin-tooltip {
        display: block;
      }
    }
  }

  .wall-post-link {
    color: $purple;

    &:hover,
    &:focus {
      color: $purple;
      text-decoration: underline;
    }

    &:visited {
      color: $purple;
    }
  }

  .wall-post-pin-tooltip {
    position: absolute;
    top: 25px;
    left: -175px;
    z-index: 99999;
    display: none;
    padding: 8px 10px;
    width: 165px;
    background: $grayLighter;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.14);
  }

  .wall-settings-box {
    position: absolute;
    top: 34px;
    right: 0;
    z-index: 99998;
    padding: 8px 10px;
    background: $grayLighter;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.14);

    h2 {
      font-size: 13px;
    }
  }

  .wall-settings-box--item--update,
  .wall-settings-box--item--destroy {
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      color: $grayDarker;
      text-decoration: underline;
    }
  }

  .wall-post-text-area-container {
    position: relative;
  }

  .wall-post-abort-editing {
    position: absolute;
    top: 32px;
    right: 8px;
    font-weight: normal;
    font-size: 24px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(45deg);
  }

  .wall-post-edit-box {
    width: 100%;
  }

  .wall-post--comments .item-tooltip {
    top: 26px;
    right: 16px;
  }

  .wall-post--year {
    position: relative;
    margin-bottom: 16px;
    text-align: center;

    p {
      position: relative;
      background-color: white;
    }

    &:before {
      position: absolute;
      top: -30px;
      left: 50%;
      width: 8px;
      height: 71px;
      background-color: $grayLighter;
      content: "";
      transform: translateX(-50%);
    }
  }

  .wall-post {
    position: relative;

    &:after {
      position: absolute;
      bottom: -16px;
      left: 50%;
      width: 8px;
      height: 16px;
      background-color: $grayLighter;
      content: "";
      transform: translateX(-50%);
    }
  }

  .border--active {
    border-color: $grayLight;
  }

  .border--active--darker {
    border-color: $gray;
  }
}
